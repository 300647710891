import React, { useEffect, useState } from "react";
import MyDivider from "./MyDivider";

const Timer = () => {
  // console.log(moment().to(moment("2022-02-06 08:00:00")));

  const Remaining = () => {
    const calculateTimeLeft = () => {
      const event = new Date("Feb 23, 2025 08:00:00");
      const difference = +event - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        const saniye = Math.floor((difference / 1000) % 60).toString();
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: saniye.length === 1 ? "0" + saniye : saniye,
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    });
    return (
      <div>
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>
    );
  };

  return (
    <div className="text-center justify-center items-center pb-4 lg:h-64  w-full text-white  text-2xl lg:text-5xl race">
      <div className=" text-center">
        <h1 className="text-sari font-extrabold italic text-2xl lg:text-4xl pt-8 pb-2 uppercase ">
          Time remaining for event
        </h1>
        <MyDivider />
      </div>
      <br />
      {<Remaining />}
    </div>
  );
};

export default Timer;
