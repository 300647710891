import { Col, Row } from "antd";
import React from "react";
import MyDivider from "./MyDivider";

const StepsSave = () => {
  return (
    <div>
      <Row align="bottom" className="bg-sari">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="bg-sari h-full ">
            <img
              src={require("../../assets/images/steps2.jpg").default}
              alt="location"
              className="w-full max-w-full"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="bg-sari h-full p-10">
            <h1 className="text-blue-900 font-extrabold text-2xl lg:text-4xl uppercase mb-1 text-center">
              Why are we doing this activity?
            </h1>
            <MyDivider />
            <div className="text-lg text-sky-900 space-y-4 -m-4">
              <br />
              <p>
                Through the funds raised during this activity, you will help
                Advocates for Dignity (AFD) and the Australian Relief
                Organisation (ARO) to raise the much-needed funds for orphans
                and students in need.{" "}
              </p>
              <p>
                As ARO and AFD, we are coming together to support orphans and
                students in need because every child deserves a chance to learn,
                grow, and build a better future. Many of these children face
                hardship, lacking access to education, safe shelter, and the
                resources necessary for a stable life.{" "}
              </p>
              <p>
                Right now, through our partnership with Syndey Muslim Cyclists
                (SMC), we are raising funds for an orphanage in Indonesia, which
                will provide a secure and nurturing environment for these
                children. The yearly expense to support one student is $1,800,
                covering their education, accommodation, food, and essential
                needs. Without this support, many would struggle to access even
                the most basic needs. Every contribution helps provide them with
                quality education, a safe home, and the chance to break free
                from the cycle of poverty. Together, we can give them hope,
                stability, and a path toward a brighter future.
              </p>
              <p>
                Shall you require a tax-deductible receipt, please email the
                office at ARO at info@aro.org.au with your proof of donation and
                we will endeavor to email you a tax-deductible receipt for your
                generous donation.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StepsSave;
