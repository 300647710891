import {
  CalendarOutlined,
  HeartOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  MenuOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Drawer, Layout, Menu, Row, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useMemo, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import AboutPage from "src/components/home/AboutPage";
import Contact from "src/components/home/Contact";
import DonateToEvent from "src/components/home/DonateToEvent";
import Donation from "src/components/home/Donation";
import Fundraisers from "src/components/home/Fundraisers";
import PastAfghan from "src/components/home/PastAfghan";
import PastEvents from "src/components/home/PastEvents";
import PastGreece from "src/components/home/PastGreece";
import Register from "src/components/home/Register";

import PastTurkiye from "src/components/home/PastTurkiye";
import HomePage from "../components/home/HomePage";

const HomeLayout = () => {
  const { Header, Content } = Layout;
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const router = useHistory();
  const stripePromise = useMemo(
    () =>
      loadStripe(
        // "pk_test_51K4fpIJVBRBDOhVwhwy1BYsQ3tbO2SClyK29z7hnzrejmGwQZu0pHTNHP1JVB1HjlSH2Thw5oUDXMO0NZmrgQtOw00DMF4FP0Q"
        //"pk_live_51K4fpIJVBRBDOhVwSbKYZdtUEWd76m7VNsov7oM4RRTszSNz0zu8RCfDKsh8hpXQ6VkaqDilnY1tHcHXMV9Ks0qF00X1YrwHvv"
        "pk_live_51Gx2DID9ry7fmAL1ZmyK2clpQGz0inDILfU4X53QhG7Od0QiLOQTPrXT9hvwRx3jwPpzgVAC9Xxm27DNUWVPvtvD005me2eXYm"
      ),
    []
  );
  const menuClick = (e) => {
    router.push("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  const MainMenu = () => (
    <Menu
      theme={lg ? "dark" : "light"}
      className="main-menum"
      mode={lg ? "horizontal" : "inline"}
      defaultSelectedKeys={actives}
      onClick={menuClick}
    >
      <Menu.Item key="homepage" icon={<HomeOutlined />}>
        HOME
      </Menu.Item>
      <Menu.Item key="about" icon={<IdcardOutlined />}>
        ABOUT
      </Menu.Item>
      <Menu.Item key="register" icon={<UserAddOutlined />}>
        REGISTRATION
      </Menu.Item>
      <Menu.Item key="fundraisers" icon={<HeartOutlined />}>
        DONATION
      </Menu.Item>
      <Menu.Item key="events" icon={<CalendarOutlined />}>
        PAST EVENTS
      </Menu.Item>
      <Menu.Item key="contact" icon={<MailOutlined />}>
        CONTACT
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className="layout overflow-hidden">
      <Header
        style={{
          // position: lg ? "fixed" : "unset",
          zIndex: 0,
          width: "100%",
        }}
      >
        <div className="mx-auto z-0" style={{ maxWidth: "1730px" }}>
          <div className=" header-bg">
            <Row
              justify="space-between"
              align="middle"
              className="mx-4 lg:mx-6 pt-2 lg:pt-4"
            >
              <Col flex="200px">
                {lg && (
                  <Space>
                    <img
                      src={require("../assets/images/rwr3.png").default}
                      alt="logo"
                      style={{ maxWidth: "200px" }}
                    />
                  </Space>
                )}
                {!lg && (
                  <img
                    src={require("../assets/images/rwr3.png").default}
                    alt="logo"
                    style={{ maxWidth: "175px", marginTop: "30px" }}
                  />
                )}
              </Col>
              <Col flex="auto">
                {lg && <MainMenu />}

                {!lg && (
                  <Button
                    className="float-right"
                    style={{ marginLeft: "5px" }}
                    icon={<MenuOutlined />}
                    size="large"
                    onClick={() => setIsCollapsed(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Header>
      <Content>
        <Drawer
          title={
            <img
              src={require("../assets/images/logo.png").default}
              width="150"
              alt="logo"
            />
          }
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu />
        </Drawer>

        <div className=" pb-6">
          <div
            className="mx-auto  lg:border-r lg:border-l border-gray-300 bg-white shadow-lg"
            style={{
              maxWidth: "1730px",
              padding: "0",
              //  paddingTop: lg ? "180px" : "unset",
              zIndex: 0,
            }}
          >
            <Switch>
              <Route path="/home" exact>
                <Redirect to="/home/homepage" />
              </Route>
              <Route path="/home/homepage">
                <HomePage />
              </Route>
              <Route path="/home/about" component={AboutPage} />
              <Route path="/home/events" component={PastEvents} />
              <Route path="/home/past-afghan-refugees" component={PastAfghan} />
              <Route path="/home/past-greece-refugees" component={PastGreece} />
              <Route
                path="/home/past-earthquake-victims"
                component={PastTurkiye}
              />

              <Route path="/home/fundraisers/:slug">
                <Elements stripe={stripePromise}>
                  <Donation />
                </Elements>
              </Route>
              <Route path="/home/fundraisers">
                <Fundraisers title="DONATION" isFull={true} />
              </Route>
              <Route path="/home/register">
                <Elements stripe={stripePromise}>
                  <Register />
                </Elements>
              </Route>
              <Route path="/home/donation">
                <Elements stripe={stripePromise}>
                  <DonateToEvent />
                </Elements>
              </Route>
              <Route path="/home/contact" component={Contact} />
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
        </div>
      </Content>
      <div
        style={{ textAlign: "center" }}
        className="bg-eeeeee lg:p-8 p-2 lg:text-base text-gray-500 "
      >
        <div>
          All rights reserved. 2025, runwalkride.com.au | Developed with{" "}
          <HeartOutlined /> React
        </div>
      </div>
    </Layout>
  );
};

export default HomeLayout;
