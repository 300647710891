import { Button, Col, Row, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { useHistory } from "react-router";
import MyDivider from "./MyDivider";

const GreeceBanner = () => {
  const router = useHistory();
  const { lg } = useBreakpoint();
  return (
    <div
      style={{
        backgroundColor: "#F29F05",
        zIndex: 9999999,
      }}
      className="-mt-40  lg:mt-0"
    >
      <div className=" mx-auto lg:ml-2">
        <Row gutter={[16, 16]} align="middle" className="pb-6">
          <Col
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-sari w-full"
          >
            <h1 className="text-blue-900 font-extrabold italic text-4xl pt-8 pb-2 text-center">
              ORGANISED BY
            </h1>
            <MyDivider />
            <div className="text-center">
              <Space>
                {" "}
                <img
                  src={require("../../assets/images/afd.png").default}
                  alt="afd"
                  className="mx-auto h-16 mb-12 mt-2"
                />
                <img
                  src={require("../../assets/images/aro.png").default}
                  alt="afd"
                  className="mx-auto h-16 ml-4 mb-12 mt-2"
                />
              </Space>
              <h1 className="text-blue-900 font-bold  text-3xl uppercase mb-1">
                Event Date <br /> Confirmed
              </h1>
              <MyDivider />
              <h1 className="text-white font-black uppercase text-5xl mt-4">
                23 Feb
              </h1>
              <h1 className="text-white font-black uppercase text-5xl">2025</h1>
              <h1 className="text-white font-black uppercase text-3xl">
                Sunday
              </h1>
              <br />
              <Button
                type="primary"
                size="large"
                className="py-1 px-10  rounded-full bg-blue-900"
                onClick={() => router.push("/home/register")}
              >
                REGISTER FOR EVENT
              </Button>
              <br />
              <br />
              <br />
            </div>
          </Col>
          <Col sm={24} md={24} lg={12} xl={12} xxl={12} className="text-center">
            <div className="text-center w-full ">
              <p className="text-3xl text-white  mb-4 lg:text-5xl font-extrabold ">
                Fundraising For
                <br /> Orphans and Students
                <br /> in Need
              </p>
              {/* <p className="text-white text-2xl font-bold mt-2">
              On February 23, 2025, a powerful earthquake struck {lg && <br />}
              Turkey's southern provinces and neighbouring Syria
            </p> */}
              <Row justify="center">
                <Col
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="flex justify-center items-center"
                >
                  <div className="flex justify-center items-center w-full">
                    <img
                      src={require("../../assets/images/aro-img.jpeg").default}
                      alt="banner"
                      className="w-auto max-w-full"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GreeceBanner;
