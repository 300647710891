import React from "react";
const AboutPage = () => {
  return (
    <div className="bg-sari overflow-hidden lg:mt-0 -mt-40">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-sari absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-red-600 font-semibold tracking-wide uppercase">
              ABOUT
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-bold tracking-tight text-f5f5f5 sm:text-4xl ">
              Run.Walk.Ride.
              <br /> For Orphans and Students in Need
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className=" object-cover object-center border border-gray-300 shadow-xl"
                    src={require("../../assets/images/aro-img.jpeg").default}
                    alt="Logo"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="booker  prose prose-indigo  mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 space-y-4 text-sky-900 text-lg">
              <p>
                Through the funds raised during this activity, you will help
                Advocates for Dignity (AFD) and the Australian Relief
                Organisation (ARO) to raise the much-needed funds for orphans
                and students in need.{" "}
              </p>
              <p>
                As ARO and AFD, we are coming together to support orphans and
                students in need because every child deserves a chance to learn,
                grow, and build a better future. Many of these children face
                hardship, lacking access to education, safe shelter, and the
                resources necessary for a stable life.{" "}
              </p>
              <p>
                Right now, through our partnership with Syndey Muslim Cyclists
                (SMC), we are raising funds for an orphanage in Indonesia, which
                will provide a secure and nurturing environment for these
                children. The yearly expense to support one student is $1,800,
                covering their education, accommodation, food, and essential
                needs. Without this support, many would struggle to access even
                the most basic needs. Every contribution helps provide them with
                quality education, a safe home, and the chance to break free
                from the cycle of poverty. Together, we can give them hope,
                stability, and a path toward a brighter future.
              </p>
              <p>
                Shall you require a tax-deductible receipt, please email the
                office at ARO at info@aro.org.au with your proof of donation and
                we will endeavor to email you a tax-deductible receipt for your
                generous donation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
