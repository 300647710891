import { EnvironmentOutlined } from "@ant-design/icons";
import { Col, Image, Row, Space, Tabs } from "antd";
import React from "react";
import MyDivider from "./MyDivider";

const Elocation = () => {
  const { TabPane } = Tabs;
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  const onChange = (v) => {};
  return (
    <div className="p-4">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <img
            src={require("../../assets/images/location.png").default}
            alt="location"
            className="w-full max-w-full"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <h1 className="text-blue-900 font-extrabold text-2xl lg:text-4xl  uppercase mb-1 text-center">
            Program & Location Details
          </h1>
          <MyDivider />
          <br />
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane
              tab={
                <>
                  <EnvironmentOutlined />
                  <span className="uppercase font-semibold text-sky-500 text-base">
                    Sydney
                  </span>
                </>
              }
              key="Sydney"
            >
              <div>
                <div className="text-base">
                  <p>
                    On Sunday, 23th of Feb, we have set up 2 activities for
                    participants to participate in, with each having an optional
                    fundraiser target as outlined below:
                    <br />
                    <br />
                  </p>
                  <b>• 6:30am start:</b>
                  <div className="ml-4">
                    <p>- 70 km cycle – $200 fundraiser target</p>
                  </div>
                  <b>• 7:30am start:</b>
                  <div className="ml-4">
                    <p>- 40 km cycle – $100 fundraiser target</p>
                    <br />
                    {/* <p>- 10 km run – $100 fundraiser target</p>
                    <p>- 5 km run – $50 fundraiser target</p>
                    <p>- 5 km walk – $50 fundraiser target</p> */}
                  </div>

                  <p>
                    For you to take part in this activity, you will need to
                    register for the event, which costs $20, and try to raise
                    the optional target fundraising amount that each participant
                    is taking part in as shown above.
                  </p>
                  <br />
                  <p>
                    All participants need to meet at Amity College Prestons
                    campus 20 minutes before their activity start time as
                    outlined above.
                  </p>
                  <br />
                  <p>
                    After the event, Amity College will host breakfast and
                    refreshments from 9:00am to 10:00am at Amity College
                    Prestons campus car park located below:
                  </p>
                </div>
                <br />
                <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                  Activity Meeting Place
                </h1>
                <div
                  className="border border-gray-300 shadow-xl !w-full"
                  dangerouslySetInnerHTML={createMarkup(
                    `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.9758193748794!2d150.87378001564463!3d-33.94175003028097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1294912b5089dd%3A0x84e9cb2a071fd379!2sAmity%20College%2C%20Prestons%20Campus!5e0!3m2!1sen!2sau!4v1654821663211!5m2!1sen!2sau" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> `
                  )}
                />
                <br />
                <Image
                  src={require("../../assets/images/area.jpg").default}
                  alt="location"
                  className="w-full max-w-full shadow-lg border border-gray-300"
                />
                <br />
                <br />
                {/* <div>
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    5km Run/Walk Path
                  </h1>
                  <Image
                    src={require("../../assets/images/runwalk5.jpeg").default}
                    alt="location"
                    className="w-full max-w-full shadow-lg border border-gray-300"
                  />
                </div>{" "}
                <br />
                <div>
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    10km Run Path
                  </h1>
                  <Image
                    src={require("../../assets/images/run10.jpeg").default}
                    alt="location"
                    className="w-full max-w-full shadow-lg border border-gray-300"
                  />
                </div>{" "} */}
                <br />
                <div>
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    40km Cycle Path
                  </h1>
                  <Image
                    src={require("../../assets/images/cycle20.jpeg").default}
                    alt="location"
                    className="w-full max-w-full shadow-lg border border-gray-300"
                  />
                </div>{" "}
                <br />
                <div>
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    70km Cycle Path
                  </h1>
                  <Image
                    src={require("../../assets/images/cycle60.jpeg").default}
                    alt="location"
                    className="w-full max-w-full shadow-lg border border-gray-300"
                  />
                </div>
                <div className="text-base">
                  <br />
                  <p>
                    We look forward to your participation to support this great
                    cause.
                  </p>
                  <br />
                  <p>
                    Our aim is to help thousands of orphans and students in
                    need.
                  </p>
                </div>
                <br />
                <div className="w-full text-center">
                  <h1 className="text-blue-900 font-extrabold text-2xl lg:text-4xl  uppercase mb-1 text-center">
                    Partners
                  </h1>
                  <Space size="large">
                    <img
                      src={require("../../assets/images/alogo.png").default}
                      alt="afd"
                      className="h-16 mt-4 object-contain"
                    />
                    <img
                      src={require("../../assets/images/galaxy.png").default}
                      alt="afd"
                      className="h-16 mt-4  object-contain"
                    />
                  </Space>
                </div>
              </div>
            </TabPane>
            {/* <TabPane
              tab={
                <>
                  <EnvironmentOutlined />
                  <span className="uppercase font-semibold text-sky-500 text-base">
                    Melbourne
                  </span>
                </>
              }
              key="Melbourne"
            >
              <div>
                <div className="text-base">
                  <div className="text-base">
                    <p>
                      On Sunday, 19th of Mar, we have set up 3 activities for
                      participants to participate in, with each having an
                      optional fundraiser target as outlined below:
                      <br />
                      <br />
                    </p>
                    <b>• 7:30am start:</b>
                    <div className="ml-4">
                      <p>- 60 km cycle – $200 fundraiser target</p>
                    </div>
                    <b>• 8:30am start:</b>
                    <div className="ml-4">
                      <p>- 10 km run – $100 fundraiser target</p>
                      <p>- 5 km walk – $50 fundraiser target</p>
                    </div>
                    <p>
                      For you to take part in this activity, you will need to
                      register for the event, which costs $20, and try to raise
                      the optional target fundraising amount that each
                      participant is taking part in as shown above.
                    </p>
                    <br />
                    <p>
                      All participants need to meet at Sirius College
                      Eastmeadows Campus 20 minutes before their activity start
                      time as outlined above.
                    </p>
                    <br />
                    <p>
                      After the event, Sirius College will host breakfast and
                      refreshments from 10:00am to 11:30am at Sirius College
                      Eastmeadows Campus.
                    </p>
                  </div>
                  <br />
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    Activity Meeting Place
                  </h1>
                  <br />
                  <Image
                    src={require("../../assets/images/em.png").default}
                    alt="location"
                    className="w-full max-w-full shadow-lg border border-gray-300"
                  />
                  <div
                    className="border border-gray-300 shadow-xl !w-full"
                    dangerouslySetInnerHTML={createMarkup(
                      `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.5008275775253!2d144.93264211586427!3d-37.684435534428275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65a96dd71f6eb%3A0x2a1eaaa1b4e55e1f!2sSirius%20College%20-%20Eastmeadows%20Campus%20(Years%207-12%20Girls)!5e0!3m2!1sen!2sau!4v1678512938361!5m2!1sen!2sau" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
                    )}
                  />
                  <br />
                  <div>
                    <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                      5km Walk Path
                    </h1>
                    <Image
                      src={require("../../assets/images/m5w.png").default}
                      alt="location"
                      className="w-full max-w-full shadow-lg border border-gray-300"
                    />
                  </div>{" "}
                  <br />
                  <div>
                    <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                      10km Run Path
                    </h1>
                    <Image
                      src={require("../../assets/images/m10r.png").default}
                      alt="location"
                      className="w-full max-w-full shadow-lg border border-gray-300"
                    />
                  </div>{" "}
                  <br />
                  <div>
                    <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                      60km Cycle Path
                    </h1>
                    <Image
                      src={require("../../assets/images/m50c.jpg").default}
                      alt="location"
                      className="w-full max-w-full shadow-lg border border-gray-300"
                    />
                  </div>{" "}
                  <br />
                  <div className="text-base">
                    <p>
                      We look forward to your participation to support this
                      great cause.
                    </p>
                    <br />
                    <p>
                      Our aim is to help thousands of earthquake victims in
                      Turkey and Syria.
                    </p>
                    <br />
                  </div>
                  <h1 className="text-blue-900 font-extrabold text-2xl lg:text-4xl  uppercase mb-1 text-center">
                    Partners
                  </h1>
                  <Space
                    wrap
                    size="large"
                    align="center"
                    className="justify-center flex"
                  >
                    <Image
                      preview={false}
                      src={require("../../assets/images/sirius2.png").default}
                      height={200}
                    />
                    <Image
                      preview={false}
                      src={require("../../assets/images/sf.png").default}
                      height={200}
                    />
                    <Image
                      preview={false}
                      src={require("../../assets/images/yn.png").default}
                      height={100}
                    />
                  </Space>
                  <br />
                </div>

                <br />
              </div>
            </TabPane>

            <TabPane
              tab={
                <>
                  <EnvironmentOutlined />
                  <span className="uppercase font-semibold text-sky-500 text-base">
                    Adelaide
                  </span>
                </>
              }
              key="Adelaide"
            >
              <div>
                <div className="text-base">
                  <div className="text-base">
                    <p>
                      On Sunday, 19th of Mar, we have set up 3 activities for
                      participants to participate in, with each having an
                      optional fundraiser target as outlined below:
                      <br />
                      <br />
                    </p>
                    <b>• 9:00am start:</b>
                    <div className="ml-4">
                      <p>- 5 km ride – $100 fundraiser target</p>
                      <p>- 5 km run – $100 fundraiser target</p>
                      <p>- 5 km walk – $50 fundraiser target</p>
                    </div>

                    <b>• Location:</b>
                    <div className="ml-4">
                      <p>
                        Carisbrooke Park - Main N Rd, Salisbury Park SA 5109
                      </p>
                    </div>
                  </div>

                  <br />
                  <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
                    Activity Meeting Place
                  </h1>
                  <div
                    className="border border-gray-300 shadow-xl !w-full"
                    dangerouslySetInnerHTML={createMarkup(
                      `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13112.041765208924!2d138.6764881!3d-34.7553298!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0b259c6991ac7%3A0xf03365545ba2120!2sCarisbrooke%20Park!5e0!3m2!1sen!2sau!4v1678526603209!5m2!1sen!2sau" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
                    )}
                  />
                  <br />
                  <h1 className="text-blue-900 font-extrabold text-2xl lg:text-4xl  uppercase mb-1 text-center">
                    Partners
                  </h1>
                  <Space
                    wrap
                    size="large"
                    align="center"
                    className="justify-center flex"
                  >
                    <Image
                      preview={false}
                      src={require("../../assets/images/mcyess.png").default}
                      height={150}
                    />
                  </Space>
                  <br />
                </div>

                <br />
              </div>
            </TabPane> */}
          </Tabs>

          {/* <h1 className="text-sky-500 font-bold text-lg lg:text-2xl   mb-1 ">
            Activity Route Map (Click to enlarge)
          </h1>

          <br />
          <Image
            src={require("../../assets/images/Park.jpg").default}
            alt="route"
          /> */}
        </Col>
      </Row>
    </div>
  );
};

export default Elocation;
