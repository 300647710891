import { HeartOutlined } from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Progress,
  Radio,
  Row,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import MyDivider from "./MyDivider";
import ShareLinks from "./ShareLinks";

const Donation = () => {
  const { slug } = useParams();
  const { lg } = useBreakpoint();
  const [value, setValue] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [member, setMember] = useState(null);
  const [raised, setRaised] = useState(0);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      const id = slug.split("-").pop();
      AdminService.getMember(id).then((data) => {
        console.log("member:", data.data.member);
        setMember(data.data.member);
        setRaised(
          data.data.member.donations.reduce(
            (total, obj) => obj.total + total,
            0
          )
        );
        form.setFieldsValue({ memberId: data.data.member.id });
      });
    }
  }, [initialRender]);

  useEffect(() => {
    form.setFieldsValue({ total: value });
  }, [value]);

  const onFinish = useCallback(
    async function (values) {
      //    event.preventDefault();

      console.log(values);
      setLoading(true);
      if (!stripe || !elements || !values.total) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        const obj = {
          ...values,
          total: values.total,
        };
        let response = await AdminService.finalizeDonation({
          ...obj,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });
        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrder({
            ...obj,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your donation!",
          });

          form.resetFields();
          console.log("gelen data", response.data);
          setValue(null);
          elements.getElement(CardElement).clear();
          setInitialRender(true);
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form, router]
  );
  const onReset = () => {
    form.resetFields();
  };
  return (
    member && (
      <div className="min-h-screen  mx-auto">
        <div className="bg-sari relative">
          <img
            src={require("../../assets/images/gift.png").default}
            alt="gift"
            className="max-w-full w-full"
          />
          <div className="lg:text-4xl text-2xl p-2 text-white font-semibold lg:absolute top-10 left-10  uppercase ">
            <p>{member.firstname + " " + member.lastname}</p>
            <p className="text-blue-900">please help me reach my goal</p>
          </div>
        </div>
        <div className="bg-sari p-4 ">
          <Row gutter={[16, 16]}>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
              <h1 className="text-blue-900 uppercase font-extrabold italic text-4xl pt-8 pb-2 text-center">
                Why am I fundraising?
              </h1>
              <MyDivider />
              <div className="text-black text-xl  lg:p-4  space-y-4">
                <p>
                  Through the funds raised during this activity, you will help
                  Advocates for Dignity (AFD) and the Australian Relief
                  Organisation (ARO) to raise the much-needed funds for orphans
                  and students in need.{" "}
                </p>
                <p>
                  As ARO and AFD, we are coming together to support orphans and
                  students in need because every child deserves a chance to
                  learn, grow, and build a better future. Many of these children
                  face hardship, lacking access to education, safe shelter, and
                  the resources necessary for a stable life.{" "}
                </p>
                <p>
                  Right now, through our partnership with Syndey Muslim Cyclists
                  (SMC), we are raising funds for an orphanage in Indonesia,
                  which will provide a secure and nurturing environment for
                  these children. The yearly expense to support one student is
                  $1,800, covering their education, accommodation, food, and
                  essential needs. Without this support, many would struggle to
                  access even the most basic needs. Every contribution helps
                  provide them with quality education, a safe home, and the
                  chance to break free from the cycle of poverty. Together, we
                  can give them hope, stability, and a path toward a brighter
                  future.
                </p>
                <p>
                  Shall you require a tax-deductible receipt, please email the
                  office at ARO at info@aro.org.au with your proof of donation
                  and we will endeavor to email you a tax-deductible receipt for
                  your generous donation.
                </p>
                <ShareLinks
                  shareUrl={window.location.href}
                  title={`${member.firstname} will attend an event organised for the benefit of victims on runwalkride.com.au. Please help him/her reach our goal. Thank you.`}
                />
                <p className="ml-2">Thank you...</p>
                <div className="lg:text-2xl text-2xl p-2 text-white font-semibold uppercase ">
                  <p>{member.firstname + " " + member.lastname}</p>
                  <img
                    src={require("../../assets/images/rwr2.png").default}
                    alt="runwalkride"
                    className="mt-4 w-48"
                  />
                </div>
              </div>
            </Col>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12} className="w-full">
              {member.donations && (
                <div className="lg:pr-10 mt-10 bg-f5f5f5 p-4 border border-amazonl rounded-lg mb-10 shadow-2xl">
                  <br />

                  <p className="text-sky-900  text-3xl font-semibold my-4 text-center">
                    {`I will ${
                      member.eventType === 1
                        ? "ride"
                        : member.eventType === 2
                        ? "run"
                        : "walk"
                    } ${member.targetkm} km for  Earthquake victims `}
                  </p>
                  <p className="text-sari  text-3xl font-semibold my-4 text-center">
                    Please help me reach my goal
                  </p>
                  <Space className="justify-between flex text-base">
                    <span>Raised</span> <span>My Goal</span>
                  </Space>
                  <Space className="justify-between flex text-2xl font-semibold">
                    <span>${raised}</span>
                    <span>${member.goal}</span>
                  </Space>
                  <Progress
                    percent={
                      raised >= member.goal
                        ? 100
                        : ((raised / member.goal) * 100).toFixed(0)
                    }
                  />
                  <p className="lg:text-xl text-base mt-2 text-gray-800 text-center font-light">
                    {member.goal > raised ? (
                      <span>
                        {member.firstname} only needs ${member.goal - raised} to
                        reach her/his goal
                      </span>
                    ) : (
                      <span>{member.firstname} achieved her/his goal</span>
                    )}
                  </p>
                  <br />
                  <Form
                    initialValues={{ isAnonymously: false }}
                    form={form}
                    onFinish={onFinish}
                    onReset={onReset}
                    {...GlobalVariables.formLayout}
                  >
                    <Form.Item name="memberId" className="hidden"></Form.Item>
                    <Form.Item
                      label="Fullname"
                      name="name"
                      rules={[
                        { required: true, message: "Fullname is required!" },
                      ]}
                    >
                      <Input
                        placeholder="Please type your fullname"
                        size={lg ? "large" : "middle"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Email is required!" },
                      ]}
                    >
                      <Input
                        placeholder="Please type your email"
                        size={lg ? "large" : "middle"}
                      />
                    </Form.Item>
                    <Form.Item label="Organisation (opt.)" name="organisation">
                      <Input
                        placeholder="Please type your organisation name (optional)"
                        size={lg ? "large" : "middle"}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Personal message"
                      name="message"
                      rules={[
                        {
                          type: "string",
                          max: 255,
                          message: "Over max length (255)",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Message to fundraiser (optional)"
                        size={lg ? "large" : "middle"}
                      />
                    </Form.Item>
                    <Form.Item
                      name="isAnonymously"
                      valuePropName="checked"
                      wrapperCol={
                        lg && {
                          offset: 6,
                          span: 18,
                        }
                      }
                    >
                      <Checkbox>
                        I’d prefer to make my donation anonymously
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      label={<span>Your Donation</span>}
                      name="total"
                      className="-mt-4"
                      rules={[
                        {
                          required: true,
                          message: "Please select your donation value",
                        },
                      ]}
                    >
                      <Radio.Group
                        value={value}
                        buttonStyle="solid"
                        style={{ marginBottom: 16 }}
                        onChange={(a) => setValue(a.target.value)}
                        size={lg ? "large" : "middle"}
                      >
                        {[20, 50, 100, 150].map((s) => (
                          <Radio.Button key={s} value={s}>
                            ${s}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                      <InputNumber
                        style={{ width: "200px" }}
                        min={0}
                        max={10000}
                        placeholder="Or type your value"
                        onChange={(a) => setValue(a)}
                        value={value}
                        addonBefore="$"
                        size={lg ? "large" : "middle"}
                      />
                    </Form.Item>
                    <Form.Item
                      wrapperCol={
                        lg && {
                          offset: 6,
                          span: 18,
                        }
                      }
                    >
                      <h4 className="text-base">
                        Please Type Your Credit/Debit Card Details
                      </h4>
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "18px",
                              fontFamily: "monospace",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "red",
                            },
                          },
                        }}
                        className="st-form"
                      />
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        disabled={!stripe || !elements}
                        block
                        style={{ maxWidth: "500px" }}
                        size="large"
                        className="text-lg h-14 leading-3"
                        icon={<HeartOutlined />}
                      >
                        Donate Now
                      </Button>
                    </Form.Item>
                  </Form>
                  <img
                    src={
                      require("../../assets/images/secure-stripe.png").default
                    }
                    alt="secure"
                    className="w-full max-w-md mx-auto"
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
        {member.donations && member.donations.length > 0 && (
          <>
            <div className="text-white bg-blue-900 text-center p-4 uppercase text-2xl lg:text-3xl">
              thank you to my sponsors
            </div>
            <div className="bg-sari p-2 lg:p-6">
              <Row gutter={[16, 16]}>
                {member.donations.map((item, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                  >
                    <Card
                      className="h-full shadow-xl border border-amazonl"
                      title={
                        <span className="text-sky-500 text-lg font-semibold">
                          {item.isAnonymously ? (
                            "Anonymously"
                          ) : item.organisation ? (
                            <span>{`${item.name} - ${item.organisation}`}</span>
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </span>
                      }
                    >
                      <p className="font-semibold text-2xl text-center">
                        ${item.total}
                      </p>
                      <p className="text-lg mt-2 text-center">{item.message}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Donation;
// import React from "react";

// const Donation = () => {
//   return (
//     <div className="justify-center text-center p-10">Donations Closed!</div>
//   );
// };

// export default Donation;
