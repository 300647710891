import React from "react";

import Elocation from "./Elocation";
import Fundraisers from "./Fundraisers";
import GreeceBanner from "./GreeceBanner";
import Sponsors from "./Sponsors";
import StepsSave from "./StepsSave";
import Timer from "./Timer";

export default function HomePage() {
  return (
    <div>
      <div>
        {/* <BannerTurkiye /> */}

        <div className="text-center justify-center items-center   w-full text-white  text-2xl lg:text-5xl "></div>
        <GreeceBanner />
        <Timer />
        <StepsSave />
        <br />
        <Elocation />
        <br />
        <Fundraisers title="LEADERBOARD" isFull={false} />
        <Sponsors />
      </div>
    </div>
  );
}
